import { Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { Link } from '../components/Link'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO
        title="MissPlatinum ブライダルジュエリーの特徴"
        description="MissPlatinumとはメーカー直販のブライダルリング。低価格・高品質の理由は店舗展開をせず品質に特化したワークショップから直接お客様へ発送しているからです。"
        page="about"
    />
)

const AboutPage: React.FC = () => {
    const muiTheme = useTheme()
    return (
        <Layout breadcrumbs={[{ name: 'ミスプラチナとは' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12} component="main">
                    <H1>
                        <Txt>MissPlatinum ブライダルジュエリーの特徴</Txt>
                    </H1>
                    <StaticImage src="../images/about/mpg-0.jpg" alt="" />
                    <StaticImage src="../images/about/mpg-1.jpg" alt="" />
                    <StaticImage src="../images/about/mpg-2.jpg" alt="" />
                    <StaticImage src="../images/about/mpg-3.jpg" alt="" />
                    <MaterialTxt size="s">
                        プラチナ：PT950ハードプラチナ
                    </MaterialTxt>
                    <MaterialTxt size="s">
                        ダイヤ：標準～最高級クラス D/FL/3EX 有
                    </MaterialTxt>
                    <StaticImage src="../images/about/mpg-4.jpg" alt="" />
                    <section>
                        <H2>
                            <Txt>~ 快適な指輪選びをご提案 ~</Txt>
                        </H2>
                        <Wrapper theme={muiTheme}>
                            <H3 theme={muiTheme}>
                                <Txt size="s">
                                    東京渋谷ワークショップで試着する
                                </Txt>
                            </H3>
                            <Body theme={muiTheme}>
                                <ImageWrapper>
                                    <StaticImage
                                        src="../images/about/salon.jpg"
                                        alt=""
                                        width={190}
                                        imgStyle={{ width: '190px' }}
                                        objectFit="contain"
                                    />
                                </ImageWrapper>
                                <Description>
                                    <Txt size="s">
                                        MissPlatinumは工場直販の通販を中心にご利用頂いておりますが、事前にご試着をご希望のお客様は是非、渋谷のワークショップショールームへお越しくださいませ。
                                        製作現場を見ながら、ショースペースではゆっくりとすべての製品を自由にご試着頂けます。
                                    </Txt>
                                    <Link to="/salon/" margin="8px 0">
                                        MissPlatinumワークショップショールームの詳細はこちら
                                    </Link>
                                </Description>
                            </Body>
                        </Wrapper>
                        <Wrapper theme={muiTheme}>
                            <H3 theme={muiTheme}>
                                <Txt size="s">
                                    自宅でラクラク♪ 無料サンプルで試着する
                                </Txt>
                            </H3>
                            <Body theme={muiTheme}>
                                <ImageWrapper>
                                    <StaticImage
                                        src="../images/about/rental.jpg"
                                        alt=""
                                        width={190}
                                        imgStyle={{ width: '190px' }}
                                        objectFit="contain"
                                    />
                                </ImageWrapper>
                                <Description>
                                    <Txt size="s">
                                        遠方のお客様、忙しく見学が難しいお客様はご自宅に試着用のサンプルリングを無料でお届け致します。
                                        おふたりでゆっくりとお試し頂ければ、通販でも安心。只今日本全国、絶賛発送中です。
                                    </Txt>
                                    <Link to="/rental/" margin="8px 0">
                                        試着用サンプルの詳細はこちら
                                    </Link>
                                </Description>
                            </Body>
                        </Wrapper>
                    </section>
                    <PriceLinkWrapper>
                        <PriceLink to="/price/">
                            <StaticImage
                                src="../images/about/price.jpg"
                                alt=""
                            />
                        </PriceLink>
                    </PriceLinkWrapper>
                </Grid>
            </Grid>
        </Layout>
    )
}

const H1 = styled('h1')({
    margin: '8px 0 12px',
    display: 'flex',
    justifyContent: 'center',
})
const H2 = styled('h2')({
    margin: '32px 0 12px',
    display: 'flex',
    justifyContent: 'center',
})
const H3 = styled('h3')(({ theme }) => ({
    margin: 0,
    padding: '10px',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    lineHeight: 1,
}))
const MaterialTxt = styled(Txt)({
    display: 'block',
    margin: '12px 8px',
})
const Wrapper = styled('article')(({ theme }) => ({
    margin: '40px 8px 0',
    border: `1px solid ${theme.palette.secondary.main}`,
}))
const Body = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row-reverse',
    },
}))
const ImageWrapper = styled('div')({
    minWidth: '190px',
    display: 'flex',
    justifyContent: 'center',
    margin: '12px',
})
const Description = styled('p')({
    display: 'flex',
    flexDirection: 'column',
    margin: '12px',
})
const PriceLinkWrapper = styled('aside')({
    marginTop: '32px',
    display: 'flex',
    justifyContent: 'center',
})
const PriceLink = styled(Link)({
    width: '80%',
})

export default AboutPage
